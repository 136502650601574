/* OrderStatusPage.css */
.order-status-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.cont-status {
  overflow-x: clip;
  overflow-y: clip;
}

.order-status-card {
  background: #2b2b2b;
  border-radius: 20px;
  padding: 20px;
  width: 350px;
  color: white;
  text-align: center;
  position: relative;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.restaurant-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.food-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.order-price div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.order-price {
  display: flex;
}

.order-price h3 {
  width: 100%;
  margin-bottom: 0;
}

.order-progress {
  margin-bottom: 20px;
  position: relative;
}

.cart-items {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #d1d1d1;
  margin-bottom: 5px;
}

.order-progress .progress-bar-container {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.progress-bar {
  background-color: #444;
  height: 8px;
  border-radius: 4px;
  position: relative;
}

.progress {
  background-color: #00aaff;
  width: 50%;
  height: 100%;
  border-radius: 4px;
}

.progress-ready {
  background-color: green !important;
  width: 100%;
}

.progress-states {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.action-icons {
  display: flex;
  justify-content: space-around;
}

.call-btn,
.message-btn {
  background-color: #333;
  border: none;
  padding: 10px;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.call-btn:hover,
.message-btn:hover {
  background-color: #00aaff;
}
