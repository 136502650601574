.cont-overview {
  height: 100svh;
  width: 90svw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}
.overview-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  margin-left: 15%;
  width: 80svw;
  justify-content: center;
}

.stats-overview {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.stat-card {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  flex: 1;
  text-align: center;
}

.stat-card h3 {
  font-size: 1.2rem;
  color: #6b7280;
}

.stat-value {
  font-size: 2rem;
  font-weight: bold;
  color: #111827;
}

.stat-change {
  font-size: 0.9rem;
  margin-top: 5px;
}

.stat-change.positive {
  color: #10b981;
}

.stat-change.negative {
  color: #ef4444;
}

.chart-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.chart-section h3 {
  margin-bottom: 15px;
  color: #6b7280;
}

.radio_cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-inputs {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #eee;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 300px;
  font-size: 14px;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: #fff;
  font-weight: 600;
}

@media (max-width: 768px) {
  .cont-overview {
    margin-left: 7%;
    overflow-x: clip;
    height: 100vh;
    width: 80svw;
    display: grid;
  }

  .overview-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    margin-left: 0%;
    width: 100svw;
    justify-content: center;
  }

  .stat-value {
    font-size: 1.2rem;
    font-weight: bold;
    color: #111827;
  }
}

@media (max-width: 1024px) {
  .overview-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    margin-left: 0px;
    width: 80svw;
    justify-content: center;
  }
}
