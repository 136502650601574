.Review-Card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  min-width: fit-content;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #a7a7a7;
}
.extract {
  padding: 0px;
  margin: 0px;
  color: blue;
  cursor: pointer;
  font-weight: bold;
}

.promoCard {
  width: 30%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  min-width: fit-content;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Promocodes-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.circle-name {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ecf1f5;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.circle-name-reviews {
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ecf1f5;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.circle-name p {
  margin: 0;
  font-weight: bold;
}

.circle-name-reviews p {
  margin: 0;
  font-weight: bold;
}

.rating-box {
  display: flex;
  justify-content: center;
  margin-top: 15%;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}

.description-box {
  margin: 20px 0;
  padding: 15px;

  border-radius: 8px;
}

.email-box {
  font-size: 16px;
  font-weight: bold;
}
.Main-rev-cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 768px) {
  .Main-rev-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .promoCard {
    width: 90%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    min-width: fit-content;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .Promocodes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
}
