body {
  margin: 0;
  padding: 0;

  font-family: "Arial", sans-serif;
}

html {
  height: 100%;
}

.input-users {
  color: black;
  border: 2px solid #333;
  border-radius: 10px;
  padding: 10px 25px;
  background: transparent;
  max-width: 100%;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.input-users:focus {
  border-color: #1976d2;
  box-shadow: 0 0 8px #1976d2;
}
.Review-Container_orders {
  width: 80svw;
  margin-left: 20%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.Review-Container_Reviews {
  width: 80svw;

  margin-left: 20%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.header-section {
  margin-left: 20%;
  width: 80svw;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px;
  z-index: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.Horizontal-divider {
  height: 2px; /* Thickness of the divider */
  background-color: #333; /* Color of the divider */
  margin: 20px 0; /* Space around the divider */
  width: 100%; /* Full width of the container */
}

.searchInput {
  padding: 10px 15px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}

.header-section h2 {
  font-size: 24px;
  margin: 0;
}

.Arrow-edit {
  display: flex;
  justify-content: flex-end;
}

.Review-Card-F {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Review-Card-F-Resrve {
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
}

.Review-Card-F:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.Review-Card-F-Resrve:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.table-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10px;
  border-radius: 20px;
}

.title-reserve {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10%;
  border-radius: 20px;
}

.Review-Card-F p {
  margin: 5px 0;
  font-size: 14px;
  color: #343a40;
}

.badge {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.btns-Admin {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.note-text {
  white-space: pre-wrap; /* Preserves spaces and line breaks, allows wrapping */
  word-wrap: break-word; /* Break long words if needed */
  overflow-wrap: break-word; /* Ensure wrapping for all browsers */
  max-width: 65%; /* Prevent the text from overflowing */
}

.btns-Admin:hover {
  background-color: #ffca2c;
}

.Check-req,
.paid,
.update-req {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
}

.Check-req p,
.paid p,
.update-req p {
  background-color: #ffc107;
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.paid p {
  color: #007bff;
}

.status-badge-Paid {
  width: fit-content;
  background-color: #28a745;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white !important;
  display: inline-block;
}

.status-badge-checkReq {
  width: fit-content;
  background-color: #dc3545;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white !important;
  display: inline-block;
  cursor: pointer;
}

.status-badge-OrderUpdate {
  width: fit-content;
  background-color: #fd7e14;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white !important;
  display: inline-block;
}
.icon {
  height: 23px;
  width: 23px;
}

.Badge-cont {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.Review-Container_orders_Staff {
  margin-left: 1%;
  width: 97svw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}
.header-section_Staff {
  margin-left: 1%;
  width: 97dvw;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.Review-Container_orders_Management {
  width: 80svw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.header-section_Management {
  width: 80svw;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px;
  z-index: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.btn-Load {
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  position: relative;
  background-color: transparent;
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.6;
}

.btn-Load::after {
  content: "";
  border-bottom: 3px double #007bff;
  width: 0;
  position: absolute;
  top: 0;
  left: 5px;
  visibility: hidden;
  opacity: 1;
  transition: 0.2s linear;
}

.btn-Load:hover::after {
  visibility: visible;
  opacity: 1;
  width: 90%;
}

.btn-Load:hover {
  letter-spacing: 2px;
  opacity: 1;
}

.red-circle {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}

.sliderSound {
  background-color: red;
  border-radius: 100px;
  padding: 1px;
  margin: 10px;
  cursor: pointer;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  align-items: center;
  position: relative;
  display: block;
  width: 51px;
  height: 29px;
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
    rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset, #22cc3f 0px 0px 0px 0px inset,
    rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.sliderSound::after {
  content: "";
  display: flex;
  top: 2.3px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #e3e3e3;
  border-radius: 200px;
  position: absolute;
  box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
  transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  will-change: left, background-color;
}

.switchSound input[type="checkbox"]:checked + .sliderSound {
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
    #22cc3f 0px 0px 0px 2px inset, #22cc3f 0px 0px 0px 24px inset,
    rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.switchSound input[type="checkbox"]:checked + .sliderSound::after {
  left: 24px;
}

.switchSound input[type="checkbox"] {
  display: none;
}

@keyframes attention {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(30%);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .Review-Container_orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100svw;
    margin-left: 0px;
  }
  .Review-Container_Reviews {
    width: 97svw;
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }

  .Review-Container_orders_Management {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80svw;
    padding: 0px;
    margin-left: 0px;
  }

  .header-section_Management {
    background-color: none;
    margin-left: 0;
    width: 100vw;
    padding: 10px;
    justify-content: center;
  }

  .header-section_Staff {
    background-color: none;
    margin-left: 0;
    width: 100vw;
    padding: 10px;
    justify-content: center;
  }

  .header-section_Management h2 {
    display: none;
  }

  .header-section_Staff h2 {
    display: none;
  }

  .header-section_Management .searchInput {
    width: fit-content;
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .header-section {
    margin-left: 0;
    width: 100vw;
    padding: 10px;
    justify-content: flex-end;
  }

  .header-section h2 {
    display: none;
  }

  .header-section .searchInput {
    width: fit-content;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .Review-Container_orders {
    margin-left: 0px;
    width: 100svw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  .Review-Container_Reviews {
    margin-left: 0px;
    width: 100svw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  .header-section {
    margin-left: 15%;
    width: 80svw;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 20px;
    z-index: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
}
