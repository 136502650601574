.Image-Head {
  height: 200px;
}
.Head-text {
  color: black;
}
.Image {
  height: 200px;
  translate: -50px;
}
.Imagenav {
  height: 50px;
}

.nxtimg {
  height: 25px;
  cursor: pointer;
}
