.logo {
  margin-top: 10px;
  height: 50px;
  width: 50px;
}

.hamburger-icon {
  position: fixed;
  cursor: pointer;
  z-index: 1000;
  display: none;
}

.sidemenu {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 240px;
  height: 100vh;
  background-color: #f8f9fc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidemenu::-webkit-scrollbar {
  display: none;
}

.sidemenu.open {
  left: 0;
}

.sidemenu img.logo {
  width: 100px;
}

.sidemenu a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  color: #343a40;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s;
}

.sidemenu a:hover {
  background-color: #e0e7ff;
  border-radius: 8px;
  color: #5c3cff;
}

.submenu .Active {
  margin-left: 10px;
  padding: 5px;
  background-color: #5c3cff7a;
  border-radius: 8px;
}

.sidemenu a p {
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
}

/* Icon size */
.sidemenu a img.icon {
  width: 25px;
  height: 25px;
}

.sidemenu .section-title {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
  margin-top: 30px;
  margin-bottom: 10px;
}

.sidemenu .logout-section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sidemenu .logout-section p {
  color: #dc3545;
  font-weight: bold;
}

/* Logout button styling */
.sidemenu .logout-section p:hover {
  background-color: #dc3545;
  border-radius: 8px;
}

.sidemenu .logout-section img.icon {
  margin-right: 10px;
}

.submenu {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.submenu p {
  padding: 5px;
  margin: 5px 0;
  font-weight: normal;
  color: #666;
  text-decoration: none;
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .hamburger-icon {
    display: block; /* Display hamburger icon on mobile */
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
  }

  .sidemenu {
    width: 240px;
    height: 100vh;
    background-color: #f8f9fc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
    left: -240px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    transition: all 0.3s ease;
    width: 200px;
    left: -200px; /* Hidden off-screen */
  }

  .sidemenu.open {
    left: 0;
  }

  .menu-icon {
    margin-bottom: 20px;
    text-align: center;
  }

  .sidemenu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }
}
