* {
  padding: 0px;
  margin: 0px;
}
.Review-Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  margin-bottom: 75px;
  width: 90%;
  border-radius: 15px;
  border: 1px solid #a7a7a7;
  overflow-x: clip;
}
.btns-red-reco-add {
  width: 60%;
  background-color: #3a75ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
.btns-red-reco-add:hover {
  background-color: #ffca2c;
}
.btns-red-reco {
  width: 60%;
  background-color: orangered;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
/* //updated */
.btns-Confirm-reco {
  width: 60%;
  background-color: #6b4933;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.Review-Card-F {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #a7a7a7;
  overflow-x: clip;
  margin-bottom: 5%;
}

.Review-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  flex-wrap: wrap;
  min-width: 100%;
}

.searchInput-menu {
  border: none;
  background: none;
  outline: none;
  color: black;
  font-size: 15px;
  padding: 24px 46px 24px 26px;
}

.Review-Container_users {
  margin-left: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
}

.Review-total {
  display: flex;
  justify-content: center;
}
.stars {
  position: absolute;
  transform: translate(0px, -25px);
  background-color: white;
  display: flex;
  grid-gap: 0.125rem;
  gap: 0.5rem;
  color: rgb(238, 203, 8);
}
.star-img {
  height: 1.25rem;
  width: 1.25rem;
}
.del-img {
  color: red;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}
.author {
  font-size: 1.2rem;
  line-height: 1.25rem;
  color: rgba(107, 114, 128, 1);
}
.description {
  line-height: 1.625;
  color: rgba(107, 114, 128, 1);
}
.bottom-part {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.phone {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.bottom-part-button {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0px, 35px);
}

.bg-admin {
  padding: 5px;
  background-color: "#e8e8e8";
}
.table-number {
  color: #6b4933;
  position: absolute;
  transform: translate(0px, -35px);
  background-color: white;
  min-width: 15%;
  display: flex;
  justify-content: center;
  grid-gap: 0.125rem;
  gap: 0.5rem;
  font-size: 25px;
}
.placement-del {
  display: flex;
  justify-content: flex-end;
}
.input-div {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgb(1, 235, 252);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.input-type {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}
.input-Cat {
  display: flex;
  min-width: 100%;
  min-height: 40px;
  align-items: center;
  gap: 8px;
  background: #fffaf6;
  border-radius: 10px;
  border: solid 1px #646464;
}

.input-Prod {
  display: flex;
  min-width: 150%;
  min-height: 40px;
  align-items: center;
  background: #fffaf6;
  border-radius: 10px;
  border: solid 1px #646464;
}

.icon {
  margin: 0px;
  padding: 0;
  height: 30px;
  width: 30px;
}

.side-menu-container {
  width: 20svw;
  height: 100vh;
  background-color: #f5f7fb;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 6%;
}

.side-menu {
  margin-top: 2%;
  width: 20svw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 5%;
}

.side-menu-div p {
  color: #333;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
}

.side-menu-div p b {
  font-weight: 600;
}

.Active {
  padding: 10px;
  background-color: #3a75ff;
  color: white !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 90%;
}

.side-menu-div {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-side {
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f5a76;
  border-radius: 15px;
}

.Check-req {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 8px;
  transform: translate(0px, -50px);
}

.update-req {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6b4933;
  color: white;
  padding: 10px;
  border-radius: 8px;
  transform: translate(25px, -50px);
}
.update-req p {
  padding: 0px;
  margin: 0px;
}
.Check-req p {
  padding: 0px;
  margin: 0px;
}
.paid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #6b4933;
  color: white;
  padding: 10px;
  border-radius: 15px;
  transform: translate(0px, -50px);
}
.paid p {
  padding: 0px;
  margin: 0px;
}

.Product-title {
  color: #6b4933;
  position: absolute;
  transform: translate(0px, -47px);
  background-color: white;
  min-width: 12%;
  display: flex;
  justify-content: center;
  grid-gap: 0.125rem;
  gap: 0.5rem;
  font-size: 25px;
}
.Product-Card {
  display: flex;
  flex-wrap: wrap;
}
.Order-details {
  display: flex;
  flex-wrap: wrap;
}
.horizontal-divider {
  border-top: 1px solid #ccc; /* Define the style of the horizontal line */
  margin: 10px 0;
}
.TNumber {
  font-size: 95px;
}
.all-details {
  border: 2px solid #ccc;
  border-radius: 15px;
}
.Notes-Card {
  display: flex;
  flex-direction: column;

  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  width: fit-content;
  border-radius: 15px;
  border: 1px solid #a7a7a7;
}

.bottom-part-Edit {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-text {
  text-overflow: ellipsis; /* Add ellipsis ("...") at the end of the text */
}

.notificationCard {
  background: transparent !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123) !important;
  border-radius: 20px !important;
}
.notification_comps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notificationHeading {
  font-family: "Dosis", sans-serif;
  color: black !important;
  font-weight: 800 !important;
}

.icon-bell {
  height: 60px;
  width: 60px;
}

.buttonContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
}

.Closebutton {
  background-color: #6b4933 !important;
  font-family: "Crimson Pro", sans-serif !important;
  font-size: 1.2em !important;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 0.7em;
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .author {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }

  .Check-req {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 5px;
    transform: translate(25px, -50px);
  }
  .Check-req p {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
  }
  .update-req {
    width: 30%;
    font-size: 14px;
  }
  .paid {
    transform: translate(25px, -50px);
  }
  .icon {
    height: 40px;
    width: 40px;
  }
  .selected-side {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2f5a76;
    color: transparent;
    height: fit-content;
    padding: 5px;
    border-radius: 12px;
  }
  .input-Prod {
    display: flex;
    min-width: 120%;
    min-height: 40px;
    align-items: center;
    gap: 8px;
    background: #fffaf6;
    border-radius: 10px;
    border: solid 1px #646464;
  }
  .side-menu {
    gap: 7%;
    position: fixed;
    bottom: 0;
    border-top-right-radius: 15px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    opacity: 90%;
  }

  .Review-Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
    padding: 15px;
    margin-bottom: 75px;
    width: 95%;
    border-radius: 15px;
    border: 1px solid #a7a7a7;
  }
  .phone {
    width: fit-content;
    display: flex;
    flex-direction: column;
  }

  .extract {
    display: none;
  }
}

@media (max-width: 768px) {
  .Review-Card {
    overflow: visible;
  }
  .Review-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    margin-left: 0%;
  }
}

@media (max-width: 1024px) {
  .Review-Card {
    overflow: visible;
    margin-bottom: 100px;
  }
}
