.images {
  border-radius: 50%;
  height: 115%;
  width: 115%;
}
.circlebg {
  border-radius: 50%;
  height: 283px;
  width: 283px;
  background-color: #fbfbfb;

  margin-bottom: 2em;
}

.circlebg-PDR {
  border-radius: 50%;
  height: 283px;
  width: 283px;
  background-color: #fbfbfb;
}

.circlebg-dineIn {
  border-radius: 50%;
  height: 283px;
  width: 283px;
}

.background {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6d6bd;
  height: 100dvh;
}
.text {
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}

.text-color {
  font-family: "Dosis", sans-serif;
  font-weight: 800;
  font-size: 28px;
  color: #6b4933;
}
.buttonText {
  color: #fbfbfb;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-size: 24px;
}
.startbtns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 2.7em;
  width: 100%;
  border: 0;
  border-radius: 12px;
  background-color: #6b4933;
  outline: none;
  cursor: pointer;
  color: #fbfbfb;
  margin-top: 1.2em;
}

.startbtns-Extra {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 2.7em;
  width: 100%;
  border: 0;
  border-radius: 12px;
  background-color: #6b4933;
  outline: none;
  cursor: pointer;
  color: #fbfbfb;
}

.social-icons-container {
  display: flex;
  gap: 10px; /* Space between icons */
}

.icon-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6b4e31; /* Light background */
  border-radius: 12px; /* Rounded corners */
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-icon {
  font-size: 24px; /* Adjust icon size */
  color: #555; /* Default icon color */
}

.facebook-icon {
  color: #ffffff;
  /* color: #3b5998; Facebook blue */
}

.instagram-icon {
  color: #ffffff;
  /* color: #e4405f; Instagram pink */
}
.social-text {
  font-size: 16px;
  color: #6b4e31;
  margin-bottom: 10px;
}
