.container-cart {
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  background-color: #e6d6bd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: clip;
}
.container-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #e6d6bd;
  height: 100svh;
  overflow-x: clip;
}
.cart-img {
  height: 100px;
}

.icon-TIT {
  display: flex;
  align-items: center;
  height: 30px;
  width: 30px;
}
.Head {
  display: flex;
  justify-content: space-between;
  font-size: 38px;
  font-weight: 800;
}
.TIT {
  display: flex;
  justify-content: flex-start;
  width: 60%;
}
.TIT-check {
  display: flex;
  justify-content: flex-start;
  width: 69%;
}

.Items {
  align-items: center;
}
.btns-checkout {
  font-size: 28px;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  bottom: 0;
  width: 100svw;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #6b4933;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  margin-top: 1.1em;
}
.btns-red {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background-color: red;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background-color: #4834d4;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px;
}
.Cart-cards {
  display: flex;
  background-color: #fffaf6;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
}

.head {
  font-family: "Dosis", sans-serif;
  font-weight: 800;
  font-size: 48px;
  color: black;
}
.bottom {
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282828;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  margin-top: 0.2em;
  margin-bottom: 0.4em;
}

.input {
  font-size: 20px;
  font-family: "Crimson Pro", sans-serif;
  font-weight: 300;
  width: 60%;
  min-height: 50px;
  padding: 0 1rem;
  color: black;
  border: none;
  border-radius: 16px;
  background-color: white;
}

.button--submit {
  min-height: 50px;
  padding: 0.5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #6b4933;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.input:focus,
.input:focus-visible {
  border-color: #3898ec;
  outline: none;
}
.Pricing {
  font-size: 24px;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  margin-top: 0.4em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  color: #fbfbfb;
}
.options p {
  font-family: "Dosis", sans-serif;
  font-size: 24px;
  font-weight: 700;
}
.input-cont {
  text-align: center;
  font-size: 24px;
  font-family: "Crimson Pro", sans-serif;
  font-weight: 300;
  width: 15%;
  min-height: 10px;
  color: black;
  border: none;
  border-radius: 16px;
  background-color: #e6d6bd;
  transition: 200ms ease-in-out;
}

.input-cont:focus {
  width: 20%;
}
.imgs {
  height: 35px;
  width: 35px;
  margin-left: 5px;
}
