.dashboard-container {
  display: flex;
  width: 100%;
  margin-left: 20%;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header input[type="text"] {
  width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.summary-cards {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background-color: #ffffff;
  padding: 20px;
  flex: 1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer; /* Adds a pointer to indicate it's interactive */
}

.card:hover {
  transform: scale(1.05); /* Scales the card up slightly on hover */
  background-color: #e3f2fd; /* Changes to a soft blue color on hover */
}
.orders-table {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.orders-table table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.orders-table th {
  color: #888;
}

.orders-table td .status {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.status.pending {
  background-color: #ffeb3b;
  color: #555;
}

.status.unfulfilled {
  background-color: #ff8d22;
  color: #fff;
}

.status.Delivered {
  background-color: #4caf50;
  color: #fff;
}

.status.fulfilled {
  background-color: #dc3545;
  color: #fff;
}

.status.Done {
  background-color: #2196f3;
  color: #fff;
}
.order-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressDelivery-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.progressDelivery-bar {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.rider-icon {
  position: absolute;
  width: 35px;
  height: auto;
  top: -15px;
  left: 0;
}

.animate-preparing {
  position: absolute;
  width: 35px;
  height: auto;
  top: -15px;
  left: 45%;
}

.animate-on-the-way {
  position: absolute;
  width: 35px;
  height: auto;
  top: -15px;
  left: 90%;
}

.progressDelivery {
  height: 100%;
  background-color: #4caf50;
  transition: width 1s ease-in-out;
  border-radius: 5px;
}

.progressDelivery-0 {
  width: 0%;
}

.progressDelivery-50 {
  width: 50%;
}

.progressDelivery-100 {
  width: 100%;
}

.progressDelivery-states {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: #666;
  padding: 5px 0;
}
.active-filter {
  color: #007bff !important;
  font-weight: bold; /* Make the text bold */
}

@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }

  .main-content {
    padding: 10px;
  }

  .summary-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 0;
  }

  .card {
    width: fit-content;
    background-color: #ffffff;
    padding: 0px;
    flex: 1 1 calc(33.33% - 20px);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease;
    cursor: pointer; /* Adds a pointer to indicate it's interactive */
    margin-bottom: 20px;
    padding: 5px;
    margin-left: 3px;
  }

  .header {
    margin-top: 10%;
    flex-direction: column;
    align-items: flex-start;
  }

  .header input[type="text"] {
    width: 100%; /* Make input full-width */
    margin-bottom: 10px; /* Add space below input */
  }

  .orders-table {
    padding: 10px;
  }

  .orders-table table {
    width: 100%;
    font-size: 12px; /* Smaller font size for better readability */
  }

  .orders-table th,
  .orders-table td {
    padding: 8px; /* Reduce padding */
  }

  .orders-table th {
    font-size: 12px; /* Smaller font size for table headers */
  }

  /* Make the table scrollable horizontally if needed */
  .orders-table {
    overflow-x: auto;
  }

  .orders-table table {
    display: block; /* Change to block for better scrolling */
    width: 100%; /* Full width */
  }

  .order-progress {
    flex-direction: column;
    align-items: flex-start;
  }

  .progressDelivery-bar-container {
    width: 100%; /* Ensure the progress bar fits within the container */
  }

  .progressDelivery-bar {
    height: 8px; /* Make the bar thinner for smaller screens */
  }

  .rider-icon {
    width: 25px; /* Make the icon smaller */
    top: -10px; /* Adjust position */
  }

  .progressDelivery-states {
    font-size: 12px; /* Smaller font size for states */
    padding: 2px 0; /* Adjust padding */
  }
}
