.sign-upuser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100svw;
  margin-bottom: 50px;
}

.Name-part {
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;
}
.email-part {
  display: flex;
  flex-direction: column;
  min-width: 90%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0px;
}
.form-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6d6bd;
  height: 100svh;
}

.wrapper {
  --input-focus: #2d8cf0;
}

.flip-card__front {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  backface-visibility: hidden;
  gap: 50px;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
}

.flip-card__form {
  justify-content: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.title {
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  text-align: center;
  font-size: 32px;
  margin-bottom: 0.5em;
}
.bodytext {
  color: #fbfbfb;
  font-family: "Crimson Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 24px;
}

.flip-card__input {
  border-radius: 5px;
  border: 2px solid var(--main-color);
  background-color: white;
  box-shadow: 4px 4px var(--main-color);
  font-size: 15px;
  font-weight: 600;
  color: var(--font-color);
  padding: 5px 10px;
  outline: none;
}

.flip-card__input:focus {
  border: 2px solid var(--input-focus);
}

.flip-card__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  height: 2em;
  border-radius: 12px;
  border: none;
  background-color: #6b4933;
  font-size: 20px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}
.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-holder-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  min-width: 100%;
}
