.payment-container {
  background-color: #e6d6bd;
  min-height: 100dvh;
  overflow: hidden;
}
.pad {
  padding-left: 10px;
  padding-right: 10px;
}
.checkout-img {
  height: 100px;
}
.table-input {
  margin-top: 2em;
  width: 80%;
}

.Table_no input {
  text-align: center;
  font-size: 22px;
  border: none;
  padding: 5px;
  width: 50%;
  border-radius: 12px;
}
.Table_no label {
  font-size: 24px;
  font-weight: 800;
}

.Table_no {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.table-input input:focus + label,
.table-input textarea:focus + label {
  transform: scale(1.2);
  transform: translateX(0.5rem);
}
.table-input textarea {
  border: none;
  border-radius: 12px;
  height: 60px;
  width: 125%;
  transition: height 0.5s ease-in-out;
}
.table-input textarea:focus {
  height: 100px;
  width: 125%;
}
.Sticky {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}
.checkout-container {
  position: relative;
}

.accordion-wrapper {
  margin-left: 10px;
  border-radius: 12px;
  position: absolute;
  width: 94%;
  z-index: 3;
  background-color: white; /* Ensure background color covers content below */
}

.content-below-accordion {
  padding-top: 50px;
}

.delivery-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

.form-group label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.form-group input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.form-row {
  display: flex;
  gap: 20px;
}

.phone-group {
  display: flex;
  align-items: center;
}

.phone-input {
  display: flex;
  align-items: center;
}

.phone-input span {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-right: none;
  border-radius: 5px 0 0 5px;
  font-size: 14px;
}

.phone-input input {
  border-left: none;
  border-radius: 0 5px 5px 0;
  padding: 10px;
  font-size: 14px;
}
.address-header {
  font-size: 18px;
  margin-bottom: 10px;
  color: black; /* Adjust color as needed */
}

.add-new-address-btn {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 15px;
  transition: background-color 0.3s;
}

.add-new-address-btn:hover {
  background-color: #555;
}

.address-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.address-card {
  background-color: #2c2c2c;
  color: white;
  padding: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
}

.address-card:hover {
  background-color: #3d3d3d;
}

.address-card.selectedAddress {
  background-color: #5c3cff;
}
