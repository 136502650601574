/* Layout of the overall grid */
.layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 20%;
}
.layout-Staff {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  margin-top: 50px;
}
.row {
  margin-bottom: 50px;
  margin-top: 50px;
}
/* Table container */
.table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100px;
}

.reserved-sign {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 5px 10px;
  border: 2px solid red;
  background-color: white;
  border-radius: 5px;
}

.reserved-sign p {
  color: red;
  font-size: 18px;
  margin: 0;
}

.reserved-sign span {
  color: red;
  font-weight: bold;
  font-size: 20px;
}

.table-number {
  font-size: 18px;
  font-weight: bold;
  margin-top: 7px;
}

.table-number-reserved {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

/* Resize the table SVGs */
.table-container img {
  width: 150px; /* Adjust to fit the container */
  height: auto;
}
