.review-container {
  background-color: #e6d6bd;
  min-height: 100dvh; /* Sets minimum height to cover the entire viewport height */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto; /* Allows scrolling if content exceeds viewport height */
  box-sizing: border-box; /* Ensures padding is included in height calculation */
}
.review-card {
  min-width: 190px;
  min-height: 254px;
  padding: 10px;
  box-sizing: border-box;
}
.rate-img {
  height: 100px;
}
.rate-cont {
  display: flex;
  justify-content: center;
}
.skip {
  color: gray;
}
