.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 0; /* Remove any padding */
  box-sizing: border-box;
  margin: 0px;
}

/* Styling for the dine-in and pick-up buttons */
.order-options {
  display: flex;
  align-items: center;
  margin-top: 3%;
  width: 10%;
  justify-content: center;
  margin-bottom: 1%;
}

/* Button styles */
.order-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.view-details-btn {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3%;
  background-color: #5c3cff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.view-details-btn-done {
  background-color: #5c3cff; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 50%; /* Circular shape */
  width: 40px; /* Width of the button */
  height: 40px; /* Height of the button */
  display: flex; /* Flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 20px; /* Font size for the tick icon */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
}

.view-details-btn-done:hover {
  background-color: #4caf50;
}

.view-details-btn:hover {
  background-color: #ffc107;
}

.selectedItem {
  padding: 5px;
  width: 100%;
  background-color: #5c3cff;
  color: white !important;
  border-radius: 8px;
}

/* Divider styling */
.divider {
  margin: 0 5%;

  font-size: 3vw; /* Responsive font size */
  color: black;
}

@media (max-width: 768px) {
  .divider {
    margin: 0 30%;

    font-size: 3vw; /* Responsive font size */
    color: black;
  }

  .selectedItem {
    padding: 5px;
    width: 150svw;
    background-color: #5c3cff;
    color: white !important;
    border-radius: 8px;
  }
}
