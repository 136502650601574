.New-text {
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  height: 112px;
  width: 70px;
  background-color: #fffaf6;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-circle-Cart {
  position: absolute;
  top: -5px; /* Adjust to move the red circle slightly above the cart */
  right: -5px; /* Adjust to move the red circle to the right of the cart */
  background-color: red;
  color: white;
  width: 20px; /* Size of the red circle */
  height: 20px; /* Size of the red circle */
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

.red-circle-Cart .text {
  font-size: 12px; /* Adjust the size of the number */
  line-height: 1; /* Ensures text is vertically centered */
  font-weight: bold;
}

.selected {
  color: white;
  font-family: "Dosis", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  height: 112px;
  width: 70px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  background-color: #6b4933;
  border-radius: 18px;
  transition: 350ms ease-in;
}
.all-img {
  border-radius: 50%;
  height: 3.3rem;
  width: 3.3rem;
}
.searchBox {
  display: flex;
  max-width: 350px;
  max-height: 40px;
  align-items: center;
  gap: 8px;
  background: #fffaf6;
  border-radius: 50px;
  position: relative;
}

.cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100svw;
}

.Products-container {
  width: 100svw;
  min-height: 100svh;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 18px;
}
.main-Background {
  background-color: #e6d6bd;
  overflow-x: clip;
}

.bar-items {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 23%;
  overflow: auto;
  overflow-y: hidden;
  overscroll-behavior-inline: contain;
}
.bar-items::-webkit-scrollbar {
  display: none;
}
.checkout {
  display: flex;
}
.checkout-container {
  display: flex;
  justify-content: flex-end;
}
.style {
  /* background-color: white; */
  background-color: #e1dddb;
  /* Comments: main color */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.header {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  overflow-x: hidden;
}
.cart-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background-color: #4834d4;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.Cards {
  background-color: #fbfbfb;
  border-radius: 30px;
  padding: 12px;
  margin-bottom: 23px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
}
.Cards-cart {
  background-color: #fffaf6;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
  margin-bottom: 10px;
}
.cart-icon {
  height: 30px;
}
.cart-icon-container {
  padding: 5px;
  border: solid 2px black;
  border-radius: 5px;
}

.title-Menu {
  font-family: "Montserrat", sans-serif;
  /* font-optical-sizing: auto; */
  font-weight: 600;
  font-size: 30px;
}
.title-Card {
  font-weight: 800;
  font-size: 24px;
}

.title-Experience {
  font-family: "Montserrat", sans-serif;
  /* font-optical-sizing: auto; */
  font-weight: 600;
  font-size: 18px;
}
.body-font {
  color: #787878;

  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}
.body-font-text {
  font-family: "Noto Sans Georgian", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.input-box {
  display: flex;
  justify-content: center;
  min-width: 100%;
}
.input-bg {
  display: flex;
  min-width: 350px;
  min-height: 40px;
  align-items: center;
  gap: 8px;
  background: #fffaf6;
  border-radius: 10px;
  border: none;
  position: relative;
}

.quantity-input {
  background-color: #e1dddb;
  max-width: 100px;
  width: 40px;
  height: 40px;
  outline: none;
  margin: 5px;
  transition: 0.5s;
  border: none;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.btns-addtoorder {
  font-size: 26px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border: 0;
  border-radius: 12px;
  background-color: #6b4933;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.btns-addtoorder-history {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: fit-content;
  height: fit-content;
  border: 0;
  border-radius: 5px;
  background-color: #6b4933;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.Cart-search {
  position: sticky;
  bottom: 0;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Cart-searchbg {
  border-radius: 20px;
  width: 40%;
  height: 40px;
  opacity: 95%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-image: linear-gradient(90deg, #885f45, #d18f64);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.loader {
  width: 60px;
  display: flex;
  justify-content: space-evenly;
}

.ball {
  list-style: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
}

.ball:nth-child(1) {
  animation: bounce-1 2.1s ease-in-out infinite;
}

.top-notification {
  position: fixed;
  top: 10px;
  left: 10%;
  width: 80%;
  background-color: white;
  color: black;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 9999;
  display: flex;

  align-items: center;
  justify-content: space-between;
}

.notification-wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: fit-content;
}
.desc-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
/* Responsive layout for notification content */
.notification-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
  height: fit-content;
}

/* Styling for the product details */
.notification-content h4 {
  margin: 0;
  font-size: 16px;
}

.notification-content h3 {
  margin: 0;
  font-size: 20px;
}

.description {
  margin-bottom: 15px;
  color: #f0f0f0;
  font-size: 14px;
}

/* Button inside the notification */
.add-button {
  background-color: #885f45;

  border: none;
  color: white;
  width: 20%;
  height: 20%;
  border-radius: 8px;
  font-size: 24px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #d18f64;
}

/* Mobile-friendly adjustments */

@keyframes bounce-1 {
  50% {
    transform: translateY(-8px);
  }
}

.ball:nth-child(2) {
  animation: bounce-3 2.1s ease-in-out 0.3s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-8px);
  }
}

.ball:nth-child(3) {
  animation: bounce-3 2.1s ease-in-out 0.6s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-8px);
  }
}
